import AddressComponent from '../../components/Address/AddressComponent';
import TokenomicsComponent from '../../components/Tokenomics/TokenomicsComponent';
import Big_Man from '../../assets/images/ManBig.png'
import './styles.css'

const Main = () =>{

    return(

        <div className='grid_wrapper'>
            
            <div className='left_grid_wrapper'>
                <img alt="Big Chad Left" src={Big_Man}/>
            </div>

            <div className='right_grid_wrapper'>
                <div className='tokenomics_wrapper'>
                    {[0,1,2,3,4].map((v, index)=>{
                        return(<TokenomicsComponent value={1} index={index}/>)
                    })}
                </div>
                <AddressComponent/>
                <div className='tokenomics_wrapper'>
                    {[0,1,2].map((v, index)=>{
                        return(<TokenomicsComponent value={2} index={index}/>)
                    })}
                </div>
            </div>

        </div>

    )
}

export default Main;