import './styles.css'

const AddressComponent = () => {
  return (
    <div className='address_bar'>
      <a className="uniswap_link" href="https://app.uniswap.org/swap?exactField=input&exactAmount=10&outputCurrency=0xFC763c6FFe37d5D2622c693386f7F86FEF0b31Fb&inputCurrency=0x4200000000000000000000000000000000000006">MEW NOW!</a>
    </div>
  );
};

export default AddressComponent;
