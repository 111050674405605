import './styles.css'
import Logo from '../../assets/images/logo.webp'

const Header = ()=>{
    return(
        <div className='header_wrapper'>
            <img alt="Logo" src={Logo}/>
            <p>MEW COIN</p>

            <div style={{display:'flex', marginLeft:'auto', marginRight:'1vw', flexDirection:'column', rowGap:'1vh'}}>
            </div>
        </div>
    )
}
export default Header;