import { useEffect, useState } from "react";
import axios from "axios";
import Price from "../../assets/images/price.png";
import "./styles.css";

const Chart = ({ cryptoId = "0xFC763c6FFe37d5D2622c693386f7F86FEF0b31Fb" }) => {
  const [price, setPrice] = useState(null);

  // useEffect(() => {
  //   const fetchPrice = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://api.coingecko.com/api/v3/simple/price?ids=${cryptoId}&vs_currencies=usd`
  //       );
  //       setPrice(response.data[cryptoId].usd);
  //     } catch (error) {
  //       console.error("Error fetching cryptocurrency price:", error);
  //     }
  //   };

  //   fetchPrice();
  //   const interval = setInterval(fetchPrice, 30000); // Update every 30 seconds

  //   return () => clearInterval(interval);
  // }, [cryptoId]);

  return (
    <div className="chart_container">
      <img alt="price_image" className="man_step_image" src={Price} />
      <h1>Initial Price</h1>
      {/* {price ? <p>${price} USD</p> : <p>Loading price...</p>} */}
      <p>0.0₁₀7642 WETH per $MEW</p>
    </div>
  );
};

export default Chart;
