import "./styles.css";

import Supply from "../../assets/images/supply.png";
import Burn from "../../assets/images/burn.png";
import Chart from "../Chart/Chart";

const TokenomicsComponent = ({ value, index }) => {
  const requireImage = () => {

    if (value === 2) {
      if (index === 0) return Supply;
      if (index === 1) return Burn;
    }
  };

  return (
    <div className="tokenomics_inner">
      {value === 1 && (
        <div className="tokenomics_container">
          {index === 0 ? (
            <div>
              <h1 className="h1">DEFLATIONARY</h1>
              <p className="text">
                A percentage of each transaction is burned to
                further mew the value.
              </p>
            </div>
          ) : index === 1 ? (
            <div>
              <h1 className="h1">TAXLESS</h1>
              <p className="text">
                What you buy is what you pay for. No small print. No hidden
                fees.
              </p>
            </div>
          ) : index === 2 ? (
            <div>
              <h1 className="h1">LOCKED IN</h1>
              <p className="text">
                Unmintable & Unmodifiable. No BS proxy contract or shady
                functions.
              </p>
            </div>
          ) : index === 3 ? (
            <div>
              <h1 className="h1">UNTRANSFERABLE</h1>
              <p className="text">
                Ownership is LOCKED and non transferable.
              </p>
            </div>
          ) :  (
            <div>
              <h1 className="h1">LIQUID</h1>
              <p className="text">
                90% of the total supply within the liquidity pool.
              </p>
            </div>
          )}
        </div>
      )}

      {value === 2 && index !== 2 && (
        <div className="image_container">
          <img
            alt="tokenomics_man_image"
            className="man_step_image"
            src={requireImage()}
          />
          {index === 0 ? (
            <>
              <h1>Initial Supply</h1>
              <p>1.000.000.000 MEW</p>
            </>
          ) : (
            <>
              <h1>Burn</h1>
              <p>0.69% Burnt Per Transaction</p>
            </>
          )}
        </div>
      )}

      {value === 2 && index === 2 && <Chart />}
    </div>
  );
};

export default TokenomicsComponent;
